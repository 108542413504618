.about{
    padding: 25px;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cont img {
    width: 200px;
    height: 240px;
    padding: 30px;
 }

 .cont{
    display:flex;
    padding:10px;
 }