.scrolled{
    position: fixed;
    top: 0;
    left: 0;
    background-color: lightblue;
}

header{
    width: 100%;
    min-height: 6vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e38aef77;
    transition: all .7s ease-in;
}