.skills{
    display: flex;
    padding: 20px;
}

.skills ul{
    padding-right: 50px;
    padding-left: 50px;
}

.skills h3{
    padding-right: 50px;
    padding-left: 35px;
}